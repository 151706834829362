import React, { MouseEvent, memo, useEffect, useMemo, useState } from 'react';
import { Rnd } from 'react-rnd';
import colors from '../../../constants/colors';
import { styled } from 'styled-components';
import { Element, PhotoElementStyle } from '../../../types/models/Elements';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  modifyElement,
  openMenu,
  resetFocus,
  setFocus,
  unlockElements,
} from '../../../constants/initialStore';
import PhotoQualityIndicator from '../PhotoQualityIndicator';
import { useDrop } from 'react-dnd';
import { ReactSVG } from 'react-svg';
import { PhotoDisplayer } from './PhotoDisplayer';
import { LoginState, useAuth } from '../../../hook/useAuth';
import useLittleScreen from '../../../hook/useLittleScreen';
import Hotjar from '@hotjar/browser';
import { useLocation } from 'react-router-dom';
import useCreationType from '../../../hook/useCreationType';
import { applyRatio } from '../../../utils/convertCMToPixel';

interface TextElementProps {
  element: Element;
  index: number;
  displayPlaceholder?: boolean;
  $correctionTranslation?: number[];
}

const PhotoElement: React.FC<TextElementProps> = ({
  element,
  index,
  displayPlaceholder = true,
  $correctionTranslation = [0, 0],
}) => {
  const location = useLocation();
  const { pathname } = location;
  let isGeneration = false;
  if (pathname.includes('/generation/')) {
    isGeneration = true;
  }
  const [originalDimensions, setOriginalDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const dispatch = useDispatch();

  const [draggindDisabled, setDraggindDisabled] = useState(false);

  const focus = useSelector((state: RootState) => state.focus.value);
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const zoom = useSelector((state: RootState) => state.zoom.value);
  const menu = useSelector((state: RootState) => state.menu.value);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);

  const [thumbnail, setThumbnail] = useState<any>(null);
  const [originalPhoto, setOriginalPhoto] = useState<any>(null);

  const indicatorVisible = useMemo(
    () => focus === element.id && element.content !== '',
    [focus, index, element.id, element.content],
  );

  const littleScreen = useLittleScreen();
  const creationType = useCreationType();
  let editorVersion: string = "v0";
  let creation: any = null;
  switch (creationType) {
    case 'carte':
      creation = useSelector(
        (state: RootState) => state.creation.present.card.value,
      );
      break;
    case 'album':
      creation = useSelector(
        (state: RootState) => state.creation.present.album
      );
      break;
    case 'calendrier':
      creation = useSelector(
        (state: RootState) => state.creation.present.calendar
      );
      break;
    case 'autre':
      creation = useSelector(
        (state: RootState) => state.creation.present.other
      );
      break;
  }
  editorVersion = creation?.editorVersion ?? "v0";

  const user = useAuth();
  const contentIsEditable = () => {
    const isAdmin =
      user.userInfo?.state === LoginState.LOGGED_IN && user.userInfo.isAdmin;
    return !(!element.contentEditable && !isAdmin);
  };
  const [, drop] = useDrop(() => {
    return {
      accept: 'PhotoItem',
      drop: (item: any) => {
        dispatch(
          modifyElement({
            id: element.id,
            modification: {
              content: item.src,
              style: {
                ...element.style,
                brightness: 1,
                saturation: 100,
                opacity: 1,
                grayscale: false,
                sepia: false,
                mirrored: false,
                shadow: false,
                translateX: 0,
                translateY: 0,
                zoom:
                  item.dimensions.height / item.dimensions.width >
                    element.height / element.width
                    ? (((item.dimensions.height / item.dimensions.width) *
                      element.width) /
                      element.height) *
                    100
                    : (((item.dimensions.width / item.dimensions.height) *
                      element.height) /
                      element.width) *
                    100,
              },
            },
          }),
        );
        dispatch(resetFocus());
      },
      collect(monitor) {
        return { isDragging: monitor.canDrop() && monitor.isOver() };
      },
    };
  }, [element]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const concernedElement = document.getElementById(
        'photoDisplay-' + element.id,
      );
      const TextMenuElement = document.getElementById('menu');
      const RemoveModal = document.getElementById('removeModal');
      const ReplaceModal = document.getElementById('replaceModal');
      const importModal = document.getElementById('importModal');
      const lockContainer = document.getElementById('lockPhotoContainer');
      const SidebarElement = document.getElementById('sidebar');
      if (
        concernedElement &&
        !concernedElement.contains(event.target) &&
        TextMenuElement &&
        !TextMenuElement.contains(event.target) &&
        SidebarElement &&
        !SidebarElement.contains(event.target) &&
        (!RemoveModal || !RemoveModal.contains(event.target)) &&
        (!ReplaceModal || !ReplaceModal.contains(event.target)) &&
        importModal &&
        !importModal.contains(event.target) &&
        (!lockContainer || !lockContainer.contains(event.targets))
      ) {
        if (focus === element.id) {
          dispatch(unlockElements());
          dispatch(resetFocus());
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [focus, element.id]);

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    // e.preventDefault();
    Hotjar.event('Appuie sur une zone photo');
    dispatch(setFocus(element.id));
    if (menu !== 1 && menu !== 9) dispatch(openMenu(1)); // 1 = Menu de photo
  };

  useEffect(() => {

  }, []);

  const getRndCustomSize = () => {
    let size: any = {
      width: width * ratio,
      height: height * ratio,
    }

    const imageAspectRatio = originalDimensions.width / originalDimensions.height;
    const parentAspectRatio = width / height;
    if (editorVersion === "v1") {
      if (element?.mustCover) {
        if (element?.mustCover) {
          if (element.content === "" || !element.content) {
            size.width = "100%";
            size.height = "100%";
          } else {
            if (width > height) {
              size.width = "100%";
              size.height = "auto";
            } else if (width < height) {
              size.width = "auto";
              size.height = "100%";
              if (originalDimensions.width < originalDimensions.height) {
                if (parentAspectRatio > imageAspectRatio) {
                  size.width = "100%";
                  size.height = "auto";
                }
              } else if (originalDimensions.width > originalDimensions.height) {
                size.width = "100%";
                size.height = "auto";
              }
            } else {
              size = null;
            }
          }
        }
      }
    }

    return size;
  }

  const getRndCustomPosition = () => {
    const position = {
      x: left * ratio + $correctionTranslation[0],
      y: top * ratio + $correctionTranslation[1],
    }

    if (editorVersion === "v1") {
      if (element?.mustCover) {
        position.x = 0;
        position.y = 0;
      }
    }

    return position;
  }

  const [rndCustomSize, setRndCustomSize] = useState<any>(getRndCustomSize());
  const [rndCustomPosition, setRndCustomPosition] = useState<any>(getRndCustomPosition());

  useEffect(() => {
    setRndCustomSize(getRndCustomSize());
    setRndCustomPosition(getRndCustomPosition());
  }, [element.mustCover]);

  useEffect(() => {
    setRndCustomSize(getRndCustomSize());
  }, [width, height, ratio, originalDimensions])

  useEffect(() => {
    setRndCustomPosition(getRndCustomPosition());
  }, [top, left, ratio])

  useEffect(() => {
    if (element.type === 'PHOTO') {
      setRndCustomSize(getRndCustomSize());
      if (element.content === "" || !element.content) return;
      let content;
      let _thumbnail = "";
      let _originalPhoto = "";
      try {
        content = JSON.parse(element.content);
        _thumbnail = content?.thumbnail ?? "";
        _originalPhoto = content?.original ?? "";
      } catch (e) {
        _thumbnail = element.content;
        _originalPhoto = element.content;
      }

      setThumbnail(_thumbnail);
      setOriginalPhoto(_originalPhoto);

      const img = new Image();
      img.src = isGeneration ? _originalPhoto : _thumbnail;

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        setOriginalDimensions({ width, height });
      };
    }
  }, [element.content]);

  useEffect(() => {
    setHeight(element.height);
    setWidth(element.width);
    setTop(element.top);
    setLeft(element.left);
  }, [element]);

  function getCssDimension() {

    let cssWidth: any = element.width * ratio;
    let cssHeight: any = element.height * ratio;

    if (editorVersion === "v1") {
      if (originalDimensions.width < originalDimensions.height) {
        cssHeight = "100%";
        if (width > height || width === height) {
          cssHeight = "auto";
        }
      } else if (originalDimensions.width > originalDimensions.height) {
        cssWidth = "100%";
        if (width < height || width === height) {
          cssWidth = "auto";
        }
      } else if (originalDimensions.width === originalDimensions.height) {
        cssHeight = "100%";
        cssWidth = "100%";
      }
    }

    return {
      width: cssWidth,
      height: cssHeight
    }
  }

  function removeRotation(element: any) {
    const style = window.getComputedStyle(element);
    const transform = style.transform;

    if (transform && transform !== "none") {
      const values = transform.match(/matrix\(([^)]+)\)/);
      if (values) {
        const matrix = values[1].split(',').map(parseFloat);

        // Rebuild the transform without rotation
        const scaleX = Math.sqrt(matrix[0] * matrix[0] + matrix[1] * matrix[1]);
        const scaleY = Math.sqrt(matrix[2] * matrix[2] + matrix[3] * matrix[3]);
        const translateX = matrix[4];
        const translateY = matrix[5];

        // Apply new transform without rotation
        element.style.transform = `scale(${scaleX}, ${scaleY}) translate(${translateX}px, ${translateY}px)`;
      }
    }
  }

  const cssDimensions = getCssDimension();

  function reapplyRotation(element: any, angle: any) {
    element.style.transform += ` rotate(${angle}deg)`;
  }

  function isElementRotated(element: any) {
    const style = window.getComputedStyle(element);
    const transform = style.transform;

    if (transform && transform !== "none") {
      // Extract the matrix values
      const values = transform.match(/matrix\(([^)]+)\)/);
      if (values) {
        const matrix = values[1].split(',').map(parseFloat);
        const angle = Math.round(Math.atan2(matrix[1], matrix[0]) * (180 / Math.PI));
        return angle !== 0 ? angle : false; // Return angle if rotated, false otherwise
      }
    }
    return false; // No rotation
  }

  const getContainerRotation = () => {
    let containerRotation = null;
    if ('opacity' in element.style) {
      containerRotation = element.style.rotationFrame;
    }
    if (editorVersion === "v1") {
      if (element.mustCover) {
        containerRotation = null;
      }
    }

    return containerRotation;
  }

  const containerRotation = getContainerRotation();

  const rndCustomComponent = <RndCustom
    scale={zoom}
    size={rndCustomSize}
    position={rndCustomPosition}
    bounds="body"
    onClick={handleClick}
    onResize={(event: any) => {
      event.stopPropagation();
      dispatch(setFocus(element.id));
      setDraggindDisabled(true);
    }}
    onDrag={(event: any) => {
      event.stopPropagation();
      dispatch(setFocus(element.id));
    }}
    onDragStop={(e: any, d: any) => {
      if (draggindDisabled) return;
      dispatch(
        modifyElement({
          id: element.id,
          element: { ...element, top: d.y / ratio, left: d.x / ratio },
        }),
      );
      if (menu !== 1 && menu !== 9) dispatch(openMenu(1)); // 1 = Menu de photo // 1 = Menu de photo
    }}
    onResizeStop={(e: any, direction: any, ref: any) => {
      dispatch(
        modifyElement({
          id: element.id,
          element: {
            ...element,
            width: parseFloat(ref.style.width.slice(0, -2)) / ratio,
            height: parseFloat(ref.style.height.slice(0, -2)) / ratio,
          },
        }),
      );
      if (menu !== 1 && menu !== 9) dispatch(openMenu(1)); // 1 = Menu de photo // 1 = Menu de photo
      setDraggindDisabled(false);
    }}
    enableResizing={{
      top: false,
      right: false,
      bottom: false,
      left: false,
      topRight: false,
      bottomRight: element.editable,
      bottomLeft: false,
      topLeft: false,
    }}
    disableDragging={!element.editable}
    dragHandleClassName="dragHandle"
    onDoubleClick={!littleScreen ? handleClick : null}
    $active={focus === element.id}
    $index={element.zIndex}
    $canClick={contentIsEditable()}
    id={'photoDisplay-' + element.id}
  >
    {element.editable && (
      <DragHandle
        $active={focus === element.id}
        className="dragHandle"
      >
        <div>
          <ReactSVG
            src="/svg/move.svg"
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 18px; height: 18px;');
            }}
            style={{
              display: 'flex',
              position: 'relative',
              top: '0px',
              left: '0px',
            }}
          />
        </div>
      </DragHandle>
    )}

    <Container
      ref={drop}
      $active={focus === element.id}
      $elementstyle={
        element.style && 'opacity' in element.style ? element.style : null
      }
      $rotation={containerRotation}
    >
      {!element.content ? (
        displayPlaceholder ? (
          <EmptyPhotoDisplayer>
            <ReactSVG src="/svg/photos_white.svg" />
          </EmptyPhotoDisplayer>
        ) : (
          <></>
        )
      ) : (
        <>
          {
            element.mustCover ? <PhotoDisplayer
              src={isGeneration ? originalPhoto : thumbnail}
              id={'photoDisplay-' + element.id}
              width={cssDimensions.width}
              height={cssDimensions.height}
              $elementstyle={
                element.style && 'opacity' in element.style ? element.style : null
              }
              $mustCover={element.mustCover ?? false}
              $ratio={ratio}
            /> :
              <PhotoDisplayer
                src={isGeneration ? originalPhoto : thumbnail}
                id={'photoDisplay-' + element.id}
                width={element.width * ratio}
                height={element.height * ratio}
                $elementstyle={
                  element.style && 'opacity' in element.style ? element.style : null
                }
                $mustCover={element.mustCover ?? false}
                $ratio={ratio}
              />
          }

        </>
      )}
    </Container>
    {element.editable && (
      <SmallSquare $position="se" />
    )}
    {indicatorVisible && (
      <PhotoQualityIndicator
        isgoodquality={
          'zoom' in element.style &&
          originalDimensions.height >=
          (element.height * element.style.zoom) / 334 &&
          originalDimensions.width >=
          (element.width * element.style.zoom) / 334
        }
      />
    )}
  </RndCustom>

  if (
    focus === element.id &&
    (element.locked ||
      (!element.editable && element.contentEditable && element.content))
  ) {
    return (
      <NotDraggableContainer $element={element} $ratio={ratio} id="not-draggable">
        <Rnd
          position={{ x: 0, y: 0 }}
          enableResizing={false}
          onDrag={(event: any, d) => {
            event.stopPropagation();
          }}
          size={rndCustomSize}
          onDragStop={(e: any, d: any) => {
            if (!element.mustCover) {
              if ('opacity' in element.style) {
                dispatch(
                  modifyElement({
                    id: element.id,
                    element: {
                      ...element,
                      style: {
                        ...element.style,
                        translateX: element.style.translateX + d.x / ratio,
                        translateY: element.style.translateY + d.y / ratio,
                      },
                    },
                  }),
                );
              }

              return;
            }

            // logic for mustCover element to decide clamp
            const rndParentElement = document.getElementById("not-draggable");
            if (!rndParentElement) return;

            const angle = isElementRotated(rndParentElement);
            if (angle) {
              removeRotation(rndParentElement);
            }

            const rndParentElementPosition = rndParentElement.getBoundingClientRect();
            const rndElem = d.node as HTMLElement;
            const imageNode = rndElem.childNodes[0];
            const imageNodePosition = (imageNode as HTMLElement).getBoundingClientRect();
            const imageY = imageNodePosition.y
            const imageX = imageNodePosition.x
            const imageBottomY = imageY + imageNodePosition.height;
            const imageRightX = imageX + imageNodePosition.width;
            const parentY = rndParentElementPosition.y;
            const parentX = rndParentElementPosition.x;
            const parentBottomY = parentY + rndParentElementPosition.height;
            const parentRightX = parentX + rndParentElementPosition.width;


            if (
              imageY > parentY || imageBottomY < parentBottomY || imageRightX < parentRightX || imageX > parentX
            ) {
              const normalTranslateY = d.lastY / ratio;
              const normalTranslateX = d.lastX / ratio;

              let differenceToClampY = 0;
              let differenceToClampX = 0;
              if (imageY > parentY) {
                differenceToClampY = (parentY - imageY) / ratio
              } else if (imageBottomY < parentBottomY) {
                differenceToClampY = (parentBottomY - imageBottomY) / ratio
              }

              if (imageX > parentX) {
                differenceToClampX = (parentX - imageX) / ratio
              } else if (imageRightX < parentRightX) {
                differenceToClampX = (parentRightX - imageRightX) / ratio;
              }

              if ("opacity" in element.style) {
                dispatch(
                  modifyElement({
                    id: element.id,
                    element: {
                      ...element,
                      style: {
                        ...element.style,
                        translateX: element.style.translateX + (normalTranslateX + differenceToClampX),
                        translateY: element.style.translateY + (normalTranslateY + differenceToClampY)
                      },
                    },
                  }),
                );
              }
            } else {
              if ('opacity' in element.style) {
                dispatch(
                  modifyElement({
                    id: element.id,
                    element: {
                      ...element,
                      style: {
                        ...element.style,
                        translateX: element.style.translateX + d.x / ratio,
                        translateY: element.style.translateY + d.y / ratio,
                      },
                    },
                  }),
                );
              }
            }

            if (angle) {
              reapplyRotation(rndParentElement, angle);
            }
          }}
          id={'photoDisplay-' + element.id}
        >
          {
            element.mustCover ? <>
              <DraggablePhotoDisplayer
                src={isGeneration ? originalPhoto : thumbnail}
                width={cssDimensions.width}
                height={cssDimensions.height}
                $elementstyle={
                  element.style && 'opacity' in element.style ? element.style : null
                }
                $mustCover={element.mustCover ?? false}
                $ratio={ratio}
              />
            </>
              :
              <DraggablePhotoDisplayer
                src={isGeneration ? originalPhoto : thumbnail}
                width={element.width * ratio}
                height={element.height * ratio}
                $elementstyle={
                  element.style && 'opacity' in element.style ? element.style : null
                }
                $mustCover={element.mustCover ?? false}
                $ratio={ratio}
              />
          }

          {indicatorVisible && (
            <PhotoQualityIndicator
              isgoodquality={
                'zoom' in element.style &&
                originalDimensions.height >=
                (element.height * element.style.zoom) / 334 &&
                originalDimensions.width >=
                (element.width * element.style.zoom) / 334
              }
            />
          )}
        </Rnd>
      </NotDraggableContainer>
    );
  }

  return (
    <>
      {
        element.mustCover ?
          editorVersion === "v1" ?
            <>
              <RndCustomContainer className="rnd-customer-container" $element={element} $ratio={ratio} $elementstyle={element.style && 'opacity' in element.style ? element.style : null}>
                {rndCustomComponent}
              </RndCustomContainer>
            </> : rndCustomComponent
          : rndCustomComponent
      }
    </>
  );
};

const Container = styled.div<{
  $active: boolean;
  $elementstyle: PhotoElementStyle | null;
  $rotation?: number | null;
}>`
  display: flex !important;
  height: 100%;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.$elementstyle?.shadow ? '5px 5px 5px ' + colors.gray700 : 'none'};
  border-radius: ${(props) =>
    props.$elementstyle?.shape === 'circle'
      ? '50%'
      : props.$elementstyle?.shape === 'arch'
        ? '50% 50% 0 0'
        : '0'};
  ${(props) =>
    props.$elementstyle?.shape === 'star' &&
    `

  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  `}
    transform: ${(props) =>
    props.$rotation
      ? 'rotate(' + props.$rotation + 'deg) '
      : ''};
  &:after {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: inherit;
    border: solid 1px
      ${(props) => (props.$active ? colors.gray400 : 'transparent')};
  }
`;

const NotDraggableContainer = styled.div<{
  $element: Element;
  $ratio: number;
}>`
  position: absolute;
  left: ${(props) => props.$element.left * props.$ratio}px;
  top: ${(props) => props.$element.top * props.$ratio}px;
  width: ${(props) => props.$element.width * props.$ratio}px;
  height: ${(props) => props.$element.height * props.$ratio}px;
  overflow: hidden;
  border: ${(props) =>
    !props.$element.editable && props.$element.contentEditable
      ? 'dashed 1px grey'
      : 'solid 1px ' + colors.error700};
  opacity: 1 !important;
  z-index: ${(props) =>
    props.$element.zIndex ? props.$element.zIndex + 50 : 46};
  transform: ${(props) =>
    'rotationFrame' in props.$element.style
      ? 'rotate(' + props.$element.style.rotationFrame + 'deg) '
      : ''};
`;

const RndCustomContainer = styled.div<{
  $element: Element,
  $ratio: number,
  $elementstyle: PhotoElementStyle | null;
}>`
    position: absolute;
    left: ${(props) => props.$element.left * props.$ratio}px;
    top: ${(props) => props.$element.top * props.$ratio}px;
    width: ${(props) => props.$element.width * props.$ratio}px;
    height: ${(props) => props.$element.height * props.$ratio}px;
      transform: ${(props) =>
    props.$elementstyle?.rotationFrame
      ? 'rotate(' + props.$elementstyle.rotationFrame + 'deg) '
      : ''};
      overflow: hidden;
`

const DragHandle = styled.div<{
  $active: boolean;
}>`
  display: ${(props) => (props.$active ? 'flex' : 'none')};
  position: absolute;

  justify-content: center;
  padding-top: 2px;

  width: 26px;
  height: 26px;
  background-color: transparent;

  border-radius: 13px;

  top: -18px;
  left: -18px;
  cursor: move;
  z-index: 100;

  & > div {
    position: relative;

    background-color: ${colors.white};

    width: 20px;
    height: 20px;
    border: solid 1px ${colors.gray400};

    border-radius: 9px;
  }
`;

const EmptyPhotoDisplayer = styled.div`
  display: grid;
  place-items: center;
  background-color: ${colors.gray200};
  width: 100%;
`;

const DraggablePhotoDisplayer = styled.img<{
  $elementstyle: PhotoElementStyle | null;
  $mustCover: boolean;
  $ratio: number;
}>`
  object-fit: ${(props) => (props.$mustCover ? 'cover' : 'contain')};
  opacity: 0.5;
  filter: blur(0px)
    ${(props) =>
    (props.$elementstyle?.brightness
      ? 'brightness(' + props.$elementstyle.brightness + ') '
      : '') +
    (props.$elementstyle?.saturation
      ? 'contrast(' + props.$elementstyle.saturation + '%) '
      : '') +
    (props.$elementstyle?.grayscale ? 'grayscale(100%) ' : '') +
    (props.$elementstyle?.sepia ? 'sepia(100%) ' : '')};
  -webkit-filter: blur(0px)
    ${(props) =>
    (props.$elementstyle?.brightness
      ? 'brightness(' + props.$elementstyle.brightness + ') '
      : '') +
    (props.$elementstyle?.saturation
      ? 'contrast(' + props.$elementstyle.saturation + '%) '
      : '') +
    (props.$elementstyle?.grayscale ? 'grayscale(100%) ' : '') +
    (props.$elementstyle?.sepia ? 'sepia(100%) ' : '')};
  transform: ${(props) =>
    (props.$elementstyle?.mirrored ? 'scaleX(-1) ' : '') +
    'translate(' +
    (props.$elementstyle?.translateX
      ? props.$elementstyle?.translateX * props.$ratio
      : 0) +
    'px, ' +
    (props.$elementstyle?.translateY
      ? props.$elementstyle?.translateY * props.$ratio
      : 0) +
    'px) ' +
    (props.$elementstyle?.rotation
      ? 'rotate(' + props.$elementstyle.rotation + 'deg) '
      : '') +
    (props.$elementstyle?.zoom
      ? 'scale(' + props.$elementstyle.zoom / 100 + ') '
      : '')};
`;

const RndCustom = styled(Rnd) <{
  $active: boolean;
  $index: number;
  $canClick: boolean;
}>`
  & > div {
    display: ${(props) => (props.$active ? 'flex' : 'none')};
  }
  display: flex;
  overflow: visible;
  z-index: ${(props) => props.$index};

  ${(props) => (props.$canClick ? '' : 'pointer-events: none;')}
`;

const SmallSquare = styled.div<{ $position: 'nw' | 'ne' | 'se' | 'sw' }>`
  display: none;
  width: 15px;
  height: 15px;
  position: absolute;
  pointer-events: none;
  background-color: ${colors.white};
  border: solid 1px ${colors.gray400};
  box-sizing: border-box;
  top: ${(props) =>
    props.$position === 'nw' || props.$position === 'ne' ? '-6px' : 'auto'};
  bottom: ${(props) =>
    props.$position === 'sw' || props.$position === 'se' ? '-6px' : 'auto'};
  left: ${(props) =>
    props.$position === 'nw' || props.$position === 'sw' ? '-6px' : 'auto'};
  right: ${(props) =>
    props.$position === 'se' || props.$position === 'ne' ? '-6px' : 'auto'};
`;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${colors.green};
  position: absolute;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  z-index: 200;
  cursor: pointer;
  padding-bottom: 2px;
`;

export default memo(PhotoElement);
