import { styled } from 'styled-components';
import { ReactNode, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState, openMenu, setFocus } from '../../constants/initialStore';
import {
  CalendarCover as CalendarCoverType,
  CalendarMonth as CalendarMonthType,
  CalendarTypes,
} from '../../types/models/Calendar';
import colors from '../../constants/colors';
import { calendarFormats } from '../../constants/calendarFormats';
import dayjs from 'dayjs';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import useElementDrop from '../../hook/useElementDrop';
import { ElementType } from '../../types/models/Elements';
import TextElement from '../../components/cardElements/textElements/TextElement';
import PhotoElement from '../../components/cardElements/photoElements/PhotoElement';
import MotifElement from '../../components/cardElements/motifElements/MotifElement';
import { mergeRefs } from 'react-merge-refs';
import CalendarGrid from './displays/CalendarGrid';
import CalendarLine from './displays/CalendarLine';
import CalendarConerSquare from './displays/CalendarConerSquare';
import CalendarSquare from './displays/CalendarSquare';
import CalendarLong from './displays/CalendrierLong';
import GridOverlay from '../../components/functionnalities/GridOverlay';
import { useTranslation } from 'react-i18next';
import { SpiraleContainer } from './CalendarPageStyles';

const MonthContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MonthTitle = styled.span`
  color: ${colors.black};
  font: 500 20px/29px DM Sans;
  line-height: 29.442px; /* 142.857% */
  align-self: flex-start;
  text-transform: capitalize;
  cursor: pointer;
`;

const CoverContainer = styled(MonthContainer)`
  grid-column: 1 / -1;
  margin-top: 30px;
`;

const CoverTitle = styled(MonthTitle)`
  align-self: center;
`;

type PageContainerProps = {
  $height: number;
  $width: number;
  $backgroundColor: string;
  $backgroundTheme?: string;
  $ratio: number;
  $displaycutzone: boolean;
  $format: string;
  $isGeneration?: boolean;
};

type DoubleSeparatorProps = {
  $ratio: number;
  $isGeneration?: boolean;
};

const PageBorder = styled.div<{ $active: boolean }>`
  position: relative;
  margin-top: 33px;
  border: solid 1px
    ${({ $active }) => ($active ? colors.gray400 : 'transparent')};
`;

const EXCLUDED_SKUS = [
    'CALA4POR2', 
    'CALWR2-RECH', 
    'CALWR2-BOIS', 
    'CALGRR-BOIS', 
    'CALGRR-RECH', 
    'CAL21x15', 
    'CAL15x15', 
    "CALA3POR1",
    'CALA5POR1',
    'CALA4POR1',
    'CALCAR30',
    'CALLONGPOR1'
];


const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  overflow: hidden;
  background-size: cover;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor && $backgroundColor !== 'null'
      ? $backgroundColor
      : colors.white};
  background-image: ${({ $backgroundTheme }) =>
    $backgroundTheme ? 'url(' + $backgroundTheme + ')' : 'none'};

&:after {
  position: absolute;
  content: '';
  inset: 0;
  pointer-events: none;
  opacity: 0.75;

  border: ${(props) =>
    props.$displaycutzone && !props.$isGeneration
      ? 'solid ' + ConvertMMToPixel(1.5) * props.$ratio + 'px ' + colors.gray400
      : 'solid ' + ConvertMMToPixel(1.5) * props.$ratio + 'px transparent'};

  ${(props) =>
    !EXCLUDED_SKUS.includes(props.$format) && !props.$isGeneration &&
    `
      border-top: solid ${ConvertMMToPixel(15) * props.$ratio}px ${colors.gray400};
    `}

  z-index: 10000;
}
`;

// height: ${(props) =>
//   !props.$isGeneration ? `${ConvertMMToPixel(30) * props.$ratio}px` : '2px'};
const DoubleSeparator = styled.div<DoubleSeparatorProps>`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
  background-color: ${colors.gray200};
`;

const ForbiddenZone = styled.div<{
  $top: number;
  $left: number;
  $width: number;
  $height: number;
}>`
  position: absolute;

  background-color: ${colors.gray700};
  opacity: 0.3;

  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  z-index: 250;
`;

export const CalendarPage = ({
  id,
  backgroundColor,
  backgroundTheme,
  children,
  isCover,
  isGeneration = false,
}: Pick<CalendarMonthType, 'id' | 'backgroundColor' | 'backgroundTheme'> & {
  children?: ReactNode;
  isCover?: boolean;
  isGeneration?: boolean;
}) => {
  const dispatch = useDispatch();
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const ratio = useSelector((state: RootState) => state.ratio!.value);
  const {
    width,
    height,
    isDouble: rawIsDouble,
    forbiddenZones,
  } = calendarFormats[format];
  const isDouble = !isCover && rawIsDouble;
  const displayHeight = (isDouble ? 2 : 1) * ConvertMMToPixel(height) * ratio;
  const displayCutZone = useSelector((state: RootState) => state.cutZone.value);

  const elRef = useRef(null);

  const monthElements = useSelector(
    (state: RootState) =>
      state.creation.present.elements.value.filter(
        (el) => el.calendarMonthID === id,
      ),
    { equalityFn: shallowEqual },
  );

  const drop = useElementDrop({ calendarMonthID: id });

  const onClick = () => {
    dispatch(setFocus(id));
    dispatch(openMenu(4));
  };
  return (
    <PageContainer
      $width={ConvertMMToPixel(width) * ratio}
      $height={displayHeight}
      $backgroundColor={backgroundColor}
      $backgroundTheme={backgroundTheme}
      $displaycutzone={displayCutZone}
      $format={format}
      $isGeneration={isGeneration}
      $ratio={ratio}
      ref={mergeRefs([elRef, drop])}
      onClick={onClick}
    >
      {children}
      {monthElements.map((element, index) => {
        if (element.noPrint && isGeneration) {
          return null;
        }
        switch (element.type) {
          case ElementType.TEXT:
            if (element.content === '' && isGeneration) {
              return null;
            }
            return (
              <TextElement key={element.id} element={element} index={index} />
            );
          case ElementType.PHOTO:
            return (
              <PhotoElement key={element.id} element={element} index={index} />
            );
          case ElementType.MOTIF:
            return (
              <MotifElement key={element.id} element={element} index={index} />
            );
        }
      })}
      {forbiddenZones !== undefined &&
        !isGeneration &&
        forbiddenZones.map((zone, index) => {
          return (
            <ForbiddenZone
              key={index}
              $top={ConvertMMToPixel(zone.top) * ratio}
              $left={ConvertMMToPixel(zone.left) * ratio}
              $width={ConvertMMToPixel(zone.width) * ratio}
              $height={ConvertMMToPixel(zone.height) * ratio}
            />
          );
        })}
    </PageContainer>
  );
};

export const CalendarCover = (props: CalendarCoverType) => {
  const dispatch = useDispatch();
  const isFocus = useSelector(
    (state: RootState) => state.focus.value === props.id,
  );
  const gridDisplay = useSelector((state: RootState) => state.grid.value);

  const { t } = useTranslation(['common']);

  const onClick = () => {
    dispatch(setFocus(props.id));
    dispatch(openMenu(4));
  };

  const type = useSelector(
    (state: RootState) => state.creation.present.calendar!.type,
  );
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );

  const { types } = calendarFormats[format];
  const ratio = useSelector((state: RootState) => state.ratio!.value);

  return (
    <CoverContainer id={'creationElement--1'}>
      <CoverTitle onClick={onClick}>{t('calendar.cover')}</CoverTitle>
      <PageBorder $active={isFocus}>
        {
            (types as any).includes(CalendarTypes.COVER_SPIRALE) &&
            <SpiraleContainer
                key="spirale-start"
                $top={0.5}
                $additionalCss='clip-path: inset(50% 0 0 0);'
                $ratio={ratio}
                src={'/spirale_calendrier_1.png'}

            />
        }

        {gridDisplay && <GridOverlay />}
        <CalendarPage {...props} isCover />
      </PageBorder>
    </CoverContainer>
  );
};

export const MonthPage = (
  props: CalendarMonthType & { isGeneration?: boolean },
) => {
  const { month } = props;
  const type = useSelector(
    (state: RootState) => state.creation.present.calendar!.type,
  );
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const { isDouble, types } = calendarFormats[format];
  const ratio = useSelector((state: RootState) => state.ratio!.value);

  const days = useMemo(() => {
    const monthStart = dayjs.utc(month);
    const monthNumber = monthStart.month();
    const days = [];
    let currentDate = dayjs.utc(monthStart);
    while (currentDate.month() === monthNumber) {
      days.push(dayjs.utc(currentDate));
      currentDate = currentDate.add(1, 'day');
    }
    return days;
  }, [month]);
  let separator: any = "";
  let lineSeparator: any = "";
  if (isDouble) {
    lineSeparator = <DoubleSeparator $ratio={ratio} $isGeneration={props.isGeneration || false} />
  } 

    if (!props?.isGeneration) {
        if ((types as any).includes(CalendarTypes.SEPARATOR_SPIRALE)) {
            separator = <SpiraleContainer 
                            $top={50}
                            key="spirale-start"
                            $ratio={ratio}
                            src={'/spirale_calendrier_1.png'}
                        />
        }
    }

  let pagesUpperSpirale: any = "";

  if ((types as any).includes(CalendarTypes.PAGES_UPPER_SPIRALE)) {
    if (!props?.isGeneration) {
        pagesUpperSpirale = <SpiraleContainer 
            $top={0}
            key="spirale-start"
            $ratio={ratio}
            src={'/spirale_calendrier_1.png'}
            $additionalCss='clip-path: inset(50% 0 0 0);'
        />
    }
  }
  
  return (
    <CalendarPage {...props} isGeneration={props.isGeneration || false }>
        {pagesUpperSpirale}
        {separator}
        {lineSeparator}
        {type === 'GRID' && <CalendarGrid days={days} ratio={ratio} {...props} />}
        {type === 'LINE' && <CalendarLine days={days} ratio={ratio} {...props} />}
        {type === 'CORNER_SQUARE' && 
            <CalendarConerSquare days={days} ratio={ratio} {...props} />
        }
        {type === 'SQUARE' && (
            <CalendarSquare days={days} ratio={ratio} {...props} />
        )}
        {type === 'LONG' && <CalendarLong days={days} ratio={ratio} {...props} />}
    </CalendarPage>
  );
};

const CalendarMonth = (
  props: CalendarMonthType & {
    index: number;
    useID: boolean;
    IDToUse?: string;
  },
) => {
  const dispatch = useDispatch();
  const isFocus = useSelector(
    (state: RootState) => state.focus.value === props.id,
  );
  const gridDisplay = useSelector((state: RootState) => state.grid.value);

  const onClick = () => {
    dispatch(setFocus(props.id));
    dispatch(openMenu(4));
  };

  return (
    <MonthContainer id={props.useID ? props.IDToUse : ''} onClick={onClick}>
      {/* <MonthTitle>{dayjs.utc(props.month).format('MMMM YYYY')}</MonthTitle> */}
      <PageBorder $active={isFocus}>
        {gridDisplay && <GridOverlay />}
        <MonthPage {...props} />
      </PageBorder>
    </MonthContainer>
  );
};

export default CalendarMonth;
