import styled from 'styled-components';
import colors from '../../constants/colors';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';

export const AlbumPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

type PageContainerProps = {
  $height: number;
  $width: number;
  $backgroundColor: string;
  $backgroundTheme?: string;
  $ratio: number;
  $displaycutzone: boolean;
  $isGeneration?: boolean;
};

export const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  background-size: cover;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor && $backgroundColor !== 'null'
      ? $backgroundColor
      : colors.white};
  background-image: ${({ $backgroundTheme }) =>
    $backgroundTheme ? 'url(' + $backgroundTheme + ')' : 'none'};
 overflow: hidden;
  &:after {
    position: absolute;
    content: '';
    inset: 0;
    pointer-events: none;
    opacity: 0.75;

    border: ${(props) =>
      props.$displaycutzone && !props.$isGeneration
        ? 'solid ' + ConvertMMToPixel(1.5) * props.$ratio + 'px ' + colors.gray400
        : 'solid ' + ConvertMMToPixel(1.5) * props.$ratio + 'px transparent'};
    z-index: 10000;
  }
`;

export const DoubleSeparator = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 2px;
  z-index: 200;
  background-color: ${colors.gray200};
`;

export const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
`;

export const SliceContainer = styled.div<
  Omit<PageContainerProps, '$displaycutzone'>
>`
  position: relative;
  align-self: start;
  background-size: cover;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  padding-right: ${(props) => (props.$isGeneration ? '80px' : '0px')};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor && $backgroundColor !== 'null'
      ? $backgroundColor
      : colors.white};
  background-image: ${({ $backgroundTheme }) =>
    $backgroundTheme ? 'url(' + $backgroundTheme + ')' : 'none'};
  cursor: pointer;

  margin-top: 1px;
`;

export const SpiraleContainer = styled.img<{
  $width: number;
  $height: number;
  $ratio: number;
}>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-self: start;
  background-size: cover;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  cursor: pointer;
  z-index: 300;
`;

export const AlbumTitle = styled.div<{ $width: number }>`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-wrap: nowrap;

  width: fit-content;

  font-family: 'DM Sans';
  font-size: ${(props) => Math.ceil(props.$width / 2)}px;
  font-weight: 500;
  line-height: ${(props) => props.$width}px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
  white-space: nowrap;
`;
